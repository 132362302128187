import React from "react"
import ConditionalImageRender, {
  GatsbyImageShape,
  GatsbyImageFile,
} from "lib/conditionalImageRender"

import * as s from "./index.styles"

export interface IProps {
  backgroundImage: GatsbyImageShape
  title: string
  partners: IPartner[]
}

export interface IPartner {
  id: string
  title: string
  image: GatsbyImageFile
  style?: {
    size?: string
    color?: string
  }
}

export const PartnersSection = ({ backgroundImage, partners }: IProps) => {
  return (
    <div>
      <s.Wrapper backgroundImage={backgroundImage}>
        <ConditionalImageRender
          content={backgroundImage}
          alt="Background"
          className="background"
        />
        <s.Content>
          {partners
            ? partners.map(p => (
                <s.PartnersDiv key={p.id}>
                  <s.Title $size="22px" $color="white">
                    <div className="title-bar" />
                    <h2 className="title-text">{p.title}</h2>
                    <div className="title-bar" />
                  </s.Title>
                  {p.image?.localFile?.childImageSharp?.gatsbyImageData ? (
                    <ConditionalImageRender
                      content={
                        p.image.localFile.childImageSharp.gatsbyImageData
                      }
                      alt={p.title}
                      className="partner-image"
                    />
                  ) : null}
                </s.PartnersDiv>
              ))
            : null}
        </s.Content>
      </s.Wrapper>
    </div>
  )
}

import React from "react"
import { IGatsbyImageData } from "gatsby-plugin-image"
import { HomePageHero } from "./BigHeroComponent/index"
import { StrapiButtonProps } from "../StrapiButton"

interface IProps {
  defaultBackground: {
    publicURL: string
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData
    }
  }
  imageFirst: boolean
  image: {
    url: string
    localFile: {
      childImageSharp: {
        fluid: {
          src: IGatsbyImageData
        }
      }
    }
  }
  description: string
  title: string
  button: StrapiButtonProps
}

export default (props: IProps) => {
  return (
    <HomePageHero
      button={props.button}
      description={props.description}
      title={props.title}
      imageFirst={props.imageFirst}
      backgroundImage={props.defaultBackground.childImageSharp.gatsbyImageData}
      image={props.image.localFile.childImageSharp.fluid.src}
    />
  )
}

import React from "react"
import StrapiButton, { StrapiButtonProps } from "components/Strapi/StrapiButton"
import ConditionalImageRender, {
  GatsbyImageShape,
} from "lib/conditionalImageRender"
import * as s from "./index.styles"

export interface IProps {
  backgroundImage: GatsbyImageShape
  title: string
  description: string
  button?: StrapiButtonProps
  image: GatsbyImageShape
  imageFirst: boolean
  style?: {
    color?: string
    fontSize?: string
    size?: string
  }
}

export interface ComponentWrapperProps {
  $imageFirst: boolean
  image?: any
}

export const HomePageHero = (props: IProps) => {
  const hasImage = Boolean(props.image)

  return (
    <s.Wrapper $imageFirst={props.imageFirst}>
      <ConditionalImageRender
        content={props.backgroundImage}
        alt="Background"
        className="background"
      />
      <s.Container $imageFirst={props.imageFirst} image={hasImage}>
        {hasImage && (
          <s.Image $imageFirst={props.imageFirst}>
            <ConditionalImageRender content={props.image} alt="Additional" />
          </s.Image>
        )}
        <s.ContentContainer $imageFirst={props.imageFirst}>
          <s.Title
            $size={props.style?.size || "22px"}
            $color={props.style?.color || "white"}
          >
            {props.title}
          </s.Title>
          <s.Description $fontSize={props.style?.fontSize || "22px"}>
            {props.description}
          </s.Description>
          {props.button && <StrapiButton size="regular" {...props.button} />}
        </s.ContentContainer>
      </s.Container>
    </s.Wrapper>
  )
}

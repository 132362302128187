import React from 'react'
import DetailsForm from 'components/UI/Form/forms/DetailsForm'
import Button from 'components/UI/Button'
import useModal from '../../hooks/useModal'
import useHubspotTracking from '../../hooks/useHubspotTracking'

interface IProps {
  children: string
  reason: string
  submitButton: any
  onSubmit: (details: {
    type: 'parent' | 'teacher' | 'homeschooler'
    firstname: string
    lastname: string
    email: string
  }) => void
}

const RequestEmailButton = (props: IProps) => {
  const { identify, trackCustomEvent, trackEvent } = useHubspotTracking()

  const { open, Modal, dismiss } = useModal(<DetailsForm {...props} onSubmit={(details) => {
    let contact_type = 'Parent'
    let homeschooler = 'false'
    if (details.type === 'homeschooler') {
      homeschooler = 'true'
    } else if (details.type === 'teacher') {
      contact_type = 'Teacher'
    }

    identify({
      email: details.email,
      firstname: details.firstname,
      lastname: details.lastname,
      homeschooler,
      contact_type,
      free_resources: 'true'
    })
    trackCustomEvent('pe19679358_request_details_form_submitted', {})
    trackEvent({ id: 'Request Details Form Submitted' })
    props.onSubmit(details)
    dismiss()
  }} />, () => {})

  return <>
    <Button theme='primary' size='small' onClick={open}>{props.children}</Button>
    { Modal }
  </>
}

export default RequestEmailButton

import React from "react"
import ConditionalImageRender from "lib/conditionalImageRender"
import * as s from "./index.styles"
import { BlogPost, BlogProps } from "./BlogPost"

export const BlogHero: React.FC<BlogProps> = ({
  backgroundImage,
  title,
  posts,
  style,
}) => (
  <s.Wrapper
    $backgroundImage={backgroundImage}
    $titleSize={style?.titleSize || "20px"}
  >
    <ConditionalImageRender
      content={backgroundImage}
      alt="Background"
      className="background"
    />
    <div>
      <s.Title $color={style?.color || "white"} $fontSize={style?.fontSize}>
        {title}
      </s.Title>
      <s.BlogPostsContainer>
        {Array.isArray(posts)
          ? posts.map((post, index) => <BlogPost key={index} {...post} />)
          : null}
      </s.BlogPostsContainer>
    </div>
  </s.Wrapper>
)

import { graphql } from "gatsby"
import React from "react"
import IndexPage from "../components/pages/Index"
import SEO from "../components/SEO"
import Layout from "../layouts/Main"

interface IProps {
  data: {
    site: {
      siteMetadata: {
        siteUrl: string
      }
    }
    strapiHomepage: {
      SEO: {
        title: string
        description: string
        url: string
        image: {
          url: string
        }
      }
      ZooHero: {
        title: string
      }
    }
  }
}
const Index = ({ data }: IProps) => (
  <Layout headerOptions={{ trustpilot: { sticky: true } }}>
    <SEO title="Home" canonical={`${data.site.siteMetadata.siteUrl}`}>
      <script type="application/ld+json">
        {`
        {
          "@context":"https://schema.org",
          "@type":"Organization",
          "name":"Night Zookeeper",
          "url":"${data.strapiHomepage.SEO.url}",
          "email":"support@nightzookeeper.com",
          "logo":"https://www.nightzookeeper.com/static/7cfbd9ef941c4ca3a042f022ca9f6c72/3eed8/will-logo.webp",
          "description":"${data.strapiHomepage.SEO.description}",
          "aggregateRating": {
            "@type":"AggregateRating",
            "reviewCount":"400",
            "ratingValue":"4.7",
            "url":"https://www.trustpilot.com/review/nightzookeeper.com"
          }
        }
      `}
      </script>
    </SEO>
    <IndexPage {...data.strapiHomepage} />
  </Layout>
)

export const query = graphql`
  {
    site {
      siteMetadata {
        siteUrl
      }
    }
    strapiHomepage {
      SEO {
        title
        description
        url
        image {
          url
        }
      }
      ZooHero {
        title
      }
    }
  }
`

export default Index

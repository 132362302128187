import styled from "styled-components"

export const Wrapper = styled.div<{
  $backgroundImage: any
  $color?: string
  $titleSize?: string
}>`
  position: relative;
  font-family: "Rammetto One", cursive;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 10px;
  box-shadow: inset 0 -25px 40px 0px rgb(50, 23, 118),
    inset 0 25px 40px 0px rgb(50, 23, 118);

  .background {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  @media (max-width: 768px) {
    padding-bottom: 20px;
    box-shadow: inset 0 -25px 10px 0px rgb(50, 23, 118),
      inset 0 25px 10px 0px rgb(50, 23, 118);
  }
`

export const BlogPost = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 10px;
  padding-bottom: 50px;

  .post-image {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 8px;
  }
`

export const AuthorInfo = styled.div`
  display: flex;
  align-items: center;
  font-family: "Libre Baskerville";
  color: rgb(244, 207, 93);
  font-size: 18px;
  height: 20px;

  .author-image {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    object-fit: contain;
    border-radius: 50%;
  }

  @media (max-width: 768px) {
    font-size: 16px;
  }
`

export const BlogPostsContainer = styled.div`
  gap: 15px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 960px;
  padding: 0px 20px;

  @media (max-width: 768px) {
    gap: 0;
    padding: 10px 20px;
    flex-direction: column;
    align-items: center;
  }
`

export const Title = styled.h2<{
  $color?: string
  $fontSize?: string
}>`
  font-size: ${props => props.$fontSize || "22px"};
  color: ${props => props.$color || "white"};
  text-align: center;
  letter-spacing: 0.73px;
  line-height: 1.4em;
  text-shadow: 0 0.14em 0 #afafaf, 0 0.28em 0 rgba(0, 0, 0, 0.4);
  font-weight: normal;
  text-wrap: wrap;
  padding: 20px 0;

  @media (max-width: 768px) {
    padding: 20px 50px 0 50px;
    font-size: 24px;
  }

  @media (max-width: 400px) {
    padding: 40px 50px 0 50px;
    font-size: 22px;
  }
`

export const Date = styled.div<{
  $fontSize?: string
  $color?: string
}>`
  font-size: ${props => props.$fontSize || "16px"};
  color: ${props => props.$color || "white"};
  margin: 10px 0;
  font-family: "Libre Baskerville";

  @media (max-width: 768px) {
    font-size: 16px;
  }
`

export const Subtitle = styled.h3<{
  $fontSize?: string
  $color?: string
}>`
  font-size: ${props => props.$fontSize || "20px"};
  font-family: "Rammetto One", cursive;
  color: ${props => props.$color || "white"};
  line-height: 1.1em;

  @media (max-width: 768px) {
    font-size: 24px;
  }
`

export const Description = styled.p`
  font-size: 16px;
  font-family: "Libre Baskerville";
`

export const scrollToTeacherCTA = (className?: string) => {
  if (typeof window === "undefined") return
  const target: HTMLElement | null = document.querySelector(
    className || ".TeacherCTAHero"
  )
  if (!target) return
  window.scrollTo({
    top: target.offsetTop - 110,
    behavior: "smooth",
  })
}

export default scrollToTeacherCTA

import styled from "styled-components"

export const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  height: 95vh;
  max-height: 868px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  box-shadow: inset 0 -20px 40px 0px rgb(50, 23, 118),
    inset 0 20px 40px 0px rgb(50, 23, 118);

  > .background-image {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute !important;
    z-index: -1;
  }
`

export const FlorenceImage = styled.div`
  > .gatsby-image-wrapper {
    height: 100% !important;
    width: 100% !important;
    img {
      object-fit: contain !important;
      object-position: 0 65%;
    }
  }
  position: absolute;
  height: 100%;
  width: 400px;
  left: 0;
  top: 0;
  transform: translate(-100%, -2%);
  animation: animate-florence-in 500ms,
    animate-florence infinite alternate-reverse 500ms ease-out;
  animation-delay: 1.5s, 2s;
  z-index: -1;

  @keyframes animate-florence-in {
    from {
      transform: translate(-100%, -2%);
    }
    to {
      transform: translate(0%, -2%);
    }
  }
  @keyframes animate-florence {
    from {
      transform: translate(0%, 0%);
    }
    to {
      transform: translate(0%, -2%);
    }
  }

  @media (max-width: 1400px) {
    width: 380px;
  }

  @media (max-width: 1250px) {
    width: 330px;
  }

  @media (max-width: 1120px) {
    display: none;
  }
`

export const SamImage = styled.div`
  > .gatsby-image-wrapper {
    height: 100% !important;
    width: 100% !important;
    right: 0;
    top: 0;
    img {
      object-fit: contain !important;
      width: 100% !important;
      height: 100% !important;
      object-position: top right;
    }
  }
  position: absolute;
  right: 0;
  top: 0;
  height: 34%;
  transform: translate(100%, -100%);
  animation: animate-sam-in 1s ease-in-out forwards,
    animate-sam 2s infinite alternate-reverse ease-in-out;
  animation-delay: 2s, 3s;

  @keyframes animate-sam {
    from {
      transform: translate(15%, 0%);
    }
    to {
      transform: translate(33%, -22%);
    }
  }
  @keyframes animate-sam-in {
    from {
      transform: translate(100%, -100%);
    }
    to {
      transform: translate(33%, -22%);
    }
  }

  @media (max-width: 900px) {
    height: 35%;
  }

  @media (max-width: 768px) {
    height: 22%;
    width: 50%;
  }
`

export const ContentContainer = styled.div`
  text-align: center;
  font-family: "Rammetto One", cursive;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  max-height: 700px;
  gap: 10px;
`
export const WillImage = styled.div`
  > .gatsby-image-wrapper {
    height: 100% !important;
    img {
      object-fit: contain !important;
      height: 100% !important;
    }
  }
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
  max-width: calc(100% - 30px);
  max-height: 443px;

  animation: animate-logo infinite alternate-reverse ease-in-out 7s;
  animation-delay: 3s;

  @keyframes animate-logo {
    from {
      transform: scale(0.9);
    }
    to {
      transform: scale(1);
    }
  }
`

export const Description = styled.h1<{
  $fontSize?: string
}>`
  font-size: ${props => props.$fontSize};
  font-family: "Libre Baskerville";
  font-weight: lighter;
  line-height: 1.4;
  margin: 0;
  padding: 5px;
`

export const Buttons = styled.div`
  margin-top: 15px;
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`

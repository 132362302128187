import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { IGatsbyImageData } from "gatsby-plugin-image"
import { BlogHero } from "./components/index"

interface Author {
  fullName: string
  avatar: {
    localFile: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData
      }
    }
  }
}

interface BlogPost {
  title: string
  slug: string
  author: Author
  excerpt: string
  originalPublishDate: string
  thumbnail: {
    id: string
    url: string
    localFile: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData
      }
    }
  }
}

interface BlogQueryResult {
  defaultBackground: {
    publicURL: string
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData
    }
  }
  allStrapiBlogPosts: {
    edges: {
      node: BlogPost
    }[]
  }
}

export default () => {
  const { defaultBackground, allStrapiBlogPosts } =
    useStaticQuery<BlogQueryResult>(graphql`
      {
        defaultBackground: file(relativePath: { eq: "purple-banner.jpg" }) {
          publicURL
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1980)
          }
        }

        allStrapiBlogPosts(
          limit: 3
          sort: { fields: [originalPublishDate], order: DESC }
        ) {
          edges {
            node {
              title
              slug
              author {
                fullName
                avatar {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(width: 30)
                    }
                  }
                }
              }
              excerpt
              originalPublishDate
              thumbnail {
                id
                url
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 500)
                  }
                }
              }
            }
          }
        }
      }
    `)

  const posts =
    allStrapiBlogPosts?.edges.map(edge => ({
      imageSrc: edge.node.thumbnail.localFile.childImageSharp.gatsbyImageData,
      date: edge.node.originalPublishDate,
      subtitle: edge.node.title,
      description: edge.node.excerpt,
      authorImage:
        edge.node.author.avatar.localFile.childImageSharp.gatsbyImageData,
      authorName: edge.node.author.fullName,
      slug: edge.node.slug,
    })) ?? null

  return (
    <BlogHero
      backgroundImage={defaultBackground.childImageSharp.gatsbyImageData}
      title="Night Zookeeper Blog"
      posts={posts}
    />
  )
}

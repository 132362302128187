import React, { useRef, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import Input from '../Input'
import Select from '../Select'

const Wrapper = styled.form`
  position: relative;
  max-width: 350px;
  font-size: 16px;
  margin: 0 auto;
  background-color: #fff;
  border: 2px solid ${props => props.theme.primary};
  color: ${props => props.theme.primary};
  padding: 20px 20px 15px 20px;
  border-radius: 14px;
  text-align: center;
  h3 {
    font-size: 1.4rem;
    margin: 0;
    line-height: 1.1;
    font-weight: bold;
  }
  // > :last-child {
  //   color: #aeaeae;
  //   font-size: 0.8rem;
  // }

  p {
    font-size: 16px;
    font-size: 1em;
    color: #333;
  }

  .field {
    margin-bottom: 8px;
  }

  .field--label {
    font-size: 14px;
    margin-bottom: 4px;
    text-align: center;
  }

  .disclaimer {
    color: #aeaeae;
    font-size: 0.8rem;
  }
`

const ErrorMessage = styled.div`
  font-size: 14px;
  background-color: ${props => props.theme.primary};
  color: #fff;
  margin-bottom: 6px;
  padding: 4px 0;
  border-radius: 6px;
`

interface IField {
  nodeType: "FIELD"
  label?: string
  name: string
  placeholder?: string
  type: "text" | "email" | "userType"
  required?: boolean
  error?: string
}

interface IText {
  nodeType: "TEXT"
  type: "title" | "paragraph"
  text: string
}

type IFormElement = IField | IText

interface IProps {
  id?: string
  fields: IFormElement[]
  submitButton: any
  onSubmit: (details: any) => void
}

const Form = (props: IProps) => {
  const [fields, setFields] = useState(props.fields)
  const [type, setType] = useState('parent')
  const theme: any = useTheme()
  const formRef = useRef<HTMLFormElement | null>(null)

  const renderTextElement = (el: IText, key: string) => {
    if (el.type === 'title') {
      return <h2 key={key}>{el.text}</h2>
    }
    if (el.type === 'paragraph') {
      return <p key={key}>{el.text}</p>
    }
  }

  const renderFieldElement = (el: IField, key: string) => {
    let c: any = null
    let label: any = null
    let error: any = null
    if (el.type === 'text') {
      c = <Input type='text' name={el.name} placeholder={el.placeholder} />
    } else if (el.type === 'email') {
      c = <Input type='email' name={el.name} placeholder={el.placeholder} />
    } else if (el.type === 'userType') {
      c = <Select
        placeholder="Are you a:"
        theme={{
          selectedColor: theme.primary,
          focusedColor: `${theme.primary}33`,
          borderColor: theme.primary,
        }}
        options={[
          { value: 'parent', label: "Parent" },
          { value: 'homeschooler', label: "Homeschooler" },
          { value: 'teacher', label: "Teacher" },
        ]}
        value={type}
        onChange={value => setType(value)}
      />
    }

    if (el.error) {
      error = <ErrorMessage>{el.error}</ErrorMessage>
    }

    if (el.label) {
      label = <div className='field--label'>{el.label}{el.required ? '*' : ''}</div>
    }

    return <div className='field' key={key}>
      {label}
      { error }
      {c}
    </div>

  }

  const renderElement = (el: IFormElement, key: string) => {
    switch (el.nodeType) {
      case 'TEXT':
        return renderTextElement(el, key)
      case 'FIELD':
        return renderFieldElement(el, key)
      default:
        return null
    }
  }

  const validateField = (field: IField, value: string) => {
    if (field.required && !value) return 'Field required'
    if (field.type === 'email' &&
      !new RegExp(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/).test(value)) return 'Please enter a valid email.'
  }

  const onSubmit = () => {
    // @ts-ignore
    const fieldNames = props.fields.map((f: IField) => f.name).filter(d => d)
    if (!formRef.current) return
    
    const data = new FormData(formRef.current)
    // @ts-ignore
    const fields = props.fields.map((field: IField) => {
      if (!field.name) return field
      const error = validateField(field, data.get(field.name)?.toString() || '')
      return { ...field, error }
    })
    const nbErrors = fields.filter(f => f.error).length
    if (nbErrors > 0) { return setFields(fields) }
    const values = fieldNames.reduce((acc, f) => {
      acc[f] = data.get(f)
      return acc
    }, {})
    props.onSubmit({
      ...values,
      type,
    })
  }

  return <Wrapper ref={formRef} id={props.id}>
    {
      fields.map((f, i) => renderElement(f, `${i}`))
    }
    {props.submitButton && <div onClick={onSubmit} role="button" tabIndex={0} onKeyPress={e => e.key === 'Enter' && onSubmit()}>
      {props.submitButton}  
    </div>}
    <p className='disclaimer'>
      By entering your email, you give Night Zookeeper permission to contact
      you about their product and services.
    </p>
  </Wrapper>
}

Form.defaultProps = {
  id: undefined
}

export default Form

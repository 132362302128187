import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { PartnersSection } from "./PartnersComponent"

export default () => {
  const { defaultBackground, strapiLandingPage } = useStaticQuery(graphql`
    {
      defaultBackground: file(relativePath: { eq: "purple-banner.jpg" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 1980)
        }
      }
      strapiLandingPage {
        PartnersGroup {
          id
          title
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, width: 900)
              }
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <PartnersSection
        key={strapiLandingPage.PartnersGroup.id}
        backgroundImage={defaultBackground.childImageSharp.gatsbyImageData}
        title={strapiLandingPage.PartnersGroup.title}
        partners={strapiLandingPage.PartnersGroup}
      />
    </>
  )
}

import React from "react"
import ConditionalImageRender, {
  GatsbyImageShape,
} from "lib/conditionalImageRender"
import { Link } from "gatsby"
import * as s from "./index.styles"

export interface BlogProps {
  posts: BlogPostProps[]
  backgroundImage: GatsbyImageShape
  title: string
  style?: {
    color?: string
    titleSize?: string
    fontSize?: string
  }
}

export interface BlogPostProps {
  imageSrc: any
  authorImage: GatsbyImageShape
  authorName: string
  slug: string
  date: string | number | Date
  subtitle: string
  description: string
  style?: {
    fontSize?: string
    color?: string
    titleSize?: string
  }
}

export const BlogPost: React.FC<BlogPostProps> = ({
  imageSrc,
  date,
  subtitle,
  description,
  style,
  authorImage,
  authorName,
  slug,
}) => {
  const dateObj = new Date(date)
  const formattedDate = `${dateObj.toLocaleString("default", {
    month: "long",
  })} ${dateObj.getDate()}, ${dateObj.getFullYear()}`
  return (
    <s.BlogPost>
      <Link to={`/blog/articles/${slug}`}>
        <ConditionalImageRender
          content={imageSrc}
          alt="Blog"
          className="post-image"
        />
        <s.Subtitle
          $fontSize={style?.fontSize || "20px"}
          $color={style?.color || "white"}
        >
          {subtitle}
        </s.Subtitle>
        <s.AuthorInfo>
          <ConditionalImageRender
            content={authorImage}
            alt="Author"
            className="author-image"
          />
          <p>{authorName}</p>
        </s.AuthorInfo>
        <s.Date
          $fontSize={style?.fontSize || "16px"}
          $color={style?.color || "white"}
        >
          {formattedDate}
        </s.Date>
        <s.Description>{description}</s.Description>
      </Link>
    </s.BlogPost>
  )
}

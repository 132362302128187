import React from 'react'
import Form from '..'

interface IProps {
  submitButton: any
  reason: string
  onSubmit: (details: {
    type: 'parent' | 'teacher' | 'homeschooler'
    firstname: string
    lastname: string
    email: string
  }) => void
}

export default (props: IProps) => {
  return <Form
    id='request-details-form'
    fields={[
      {
        nodeType: 'TEXT',
        type: 'title',
        text: 'Enter your details'
      },
      {
        nodeType: 'TEXT',
        type: 'paragraph',
        text: props.reason
      },
      {
        nodeType: 'FIELD',
        type: "text",
        name: "firstname",
        label: "First Name",
        required: true,
        placeholder: ""
      },
      {
        nodeType: 'FIELD',
        type: "text",
        name: "lastname",
        label: "Last Name",
        required: true,
        placeholder: ""
      },
      {
        nodeType: 'FIELD',
        type: "email",
        name: "email",
        label: "Email",
        required: true,
        placeholder: ""
      },
      {
        nodeType: 'FIELD',
        type: "userType",
        name: "type",
        label: "I am a",
        required: false,
        placeholder: ""
      }
    ]}
    submitButton={props.submitButton}
    onSubmit={props.onSubmit}
  />
}
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import HomePageHero from "components/Strapi/HomePageHero"
import BlogHero from "components/Strapi/BlogHero"
import PartnersComponent from "components/Strapi/PartnersComponent"
import { LandingHero } from "components/Strapi/LandingPageHero/components/LandingHero"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    {
      defaultBackground: file(relativePath: { eq: "purple-banner.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 1980)
        }
      }

      strapiLandingPage {
        bigHero {
          description
          button {
            theme
            targetUrl
            value
          }
        }
        heroes {
          id
          imageFirst
          image {
            url
            localFile {
              childImageSharp {
                fluid(maxWidth: 480) {
                  src
                }
                gatsbyImageData
              }
            }
          }
          description
          title
          button {
            theme
            value
            targetUrl
          }
        }
      }
    }
  `)

  return (
    <>
      <LandingHero
        buttons={[data?.strapiLandingPage?.bigHero.button]}
        description={data?.strapiLandingPage?.bigHero.description}
      />
      {(data?.strapiLandingPage?.heroes || []).map((hero: any, id: number) => (
        <HomePageHero
          key={id}
          {...hero}
          defaultBackground={data.defaultBackground}
        />
      ))}
      <BlogHero />
      <PartnersComponent />
    </>
  )
}

export default IndexPage

import { GatsbyImageShape } from "lib/conditionalImageRender"
import styled from "styled-components"

export interface WrapperProps {
  backgroundImage: string | GatsbyImageShape
}

export const Wrapper = styled.section<WrapperProps>`
  width: 100%;
  height: 100%;
  font-family: "Rammetto One", cursive;
  color: #fff;
  font-size: 1.6rem;
  padding: 66px 10px 50px 10px;
  margin: 0 auto;
  position: relative;
  box-shadow: inset 0 -25px 40px 0px rgb(50, 23, 118),
    inset 0 25px 40px 0px rgb(50, 23, 118);

  .background {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
`

export const Title = styled.div<{
  $size?: string
  $color?: string
}>`
  display: flex;
  flex: 1;
  align-items: center;
  margin-bottom: 20px;
  color: ${props => props.$color};
  letter-spacing: 0.73px;
  line-height: 1.4em;
  text-shadow: 0 0.14em 0 #afafaf, 0 0.28em 0 rgba(0, 0, 0, 0.4);
  font-weight: normal;
  height: 2px;
  text-align: center;
  padding-bottom: 20px;

  > .title-bar {
    flex: 1;
    height: 2px;
    background-color: ${props => props.$color};
  }

  > .title-text {
    text-align: center;
    font-size: ${props => props.$size || "22px"};
    flex: 1;
    margin: 0;
    font-weight: 400;
    line-height: 1.1;
    padding: 20px 50px;
  }

  @media (max-width: 768px) {
    > .title-text {
      font-size: 20px;
    }
  }

  @media (max-width: 576px) {
    > .title-text {
      font-size: 20px;
      padding: 0;
      flex: auto;
    }
  }
`

export const Content = styled.div`
  margin: 0 auto;
  max-width: 960px;
  }
`

export const PartnersDiv = styled.div`
  margin-bottom: 50px;

  .partner-image {
    display: block;
    margin: 0 auto;
  }
`

import React from "react"
import { Link } from "gatsby"
import { Button } from "nzk-react-components"
import RequestEmailButton from "components/Buttons/RequestEmailButton"
import useLoginModal from "components/LoginModal/useLoginModal"
import LocalButton from "../../UI/Button"
import scrollToPlansSection from "../actions/scrollToPlansSection"
import scrollToTeacherCTA from "../actions/scrollToTeacherCTA"

const ACTIONS = {
  SCROLL_TO_PLANS: scrollToPlansSection,
  SCROLL_TO_TEACHER_CTA: scrollToTeacherCTA,
  LOGIN_POPUP: () => {},
}

export interface StrapiButtonProps extends Strapi.StrapiButton {
  size?: "small" | "x-small" | "regular" | "large" | "x-large" | undefined
  theme: "confirm" | "primary" | "the-pink" | "purple"
}

const StrapiButton = (props: StrapiButtonProps) => {
  const loginModal = useLoginModal()

  const onClick =
    props.action && ACTIONS[props.action]
      ? () => {
          ACTIONS[props.action!]()
        }
      : undefined

  if (props.action === "LOGIN_POPUP") {
    return (
      <>
        {loginModal}
        <Button
          size={props.size}
          theme={props.theme}
          onClick={() => {
            loginModal[1]()
          }}
        >
          {props.value}
        </Button>
      </>
    )
  }

  const button =
    typeof window !== "undefined" ? (
      <Button size={props.size} theme={props.theme} onClick={onClick}>
        {props.value}
      </Button>
    ) : (
      // @ts-ignore
      <LocalButton size={props.size} theme={props.theme} onClick={onClick}>
        {props.value}
      </LocalButton>
    )

  if (props.requestDetails) {
    return (
      <RequestEmailButton
        submitButton={
          <Button size="small" theme="primary">
            Submit
          </Button>
        }
        reason={props.requestDetailsReason || ""}
        onSubmit={() => {
          if (props.targetUrl) {
            window.open(props.targetUrl)
          }
        }}
      >
        {props.value}
      </RequestEmailButton>
    )
  }

  if (props.targetUrl) {
    return <Link to={props.targetUrl}>{button}</Link>
  }
  return button
}

StrapiButton.defaultProps = {
  size: "regular",
}

export default StrapiButton

import React from "react"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import getGatsbyImage from "utils/getGatsbyImage"

export type GatsbyImageFile = {
  localFile?: {
    childImageSharp?: {
      gatsbyImageData: IGatsbyImageData
    }
  }
}

export type GatsbyImageShape = IGatsbyImageData

const ConditionalImageRender: React.FC<{
  content: GatsbyImageFile | GatsbyImageShape
  className?: string
  alt: string
}> = props => {
  const { content, className, alt } = props

  if (!content) {
    return null
  }

  if (typeof content === "string") {
    return <img className={className} alt={alt} src={content} />
  }

  return (
    <GatsbyImage
      image={getGatsbyImage(content)}
      alt={alt}
      className={className}
    />
  )
}

export default ConditionalImageRender
